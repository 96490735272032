<template>
  <div>
    <p>Handling callback...</p>
  </div>
</template>

<script type="text/babel">
    import oktaAuth from '@/auth/auth.js';
    import api from '../assets/api.js';
    export default {
        name: "callback",
        data() {
            return {
                showLogin: false,
            }
        },
        async created() {
          try {
            // Extract code and state from the URL query parameters
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const state = urlParams.get('state');
            const error = urlParams.get('error');
            const error_description = urlParams.get('error_description');

            console.log('Code:', code);
            console.log('State:', state);

            if(error){
              this.$router.replace('/error');
              throw new Error(error_description)
            }

            // Optional: Validate the state parameter by comparing it to the stored state
            const originalState = sessionStorage.getItem('originalState');
            if (state !== originalState) {
              console.error('State does not match!');
              return;
            }

            // Exchange the authorization code for tokens using Okta SDK
            const tokens = await oktaAuth.token.parseFromUrl();
            console.log('Tokens:', tokens.tokens.accessToken.value);

            // Handle the tokens, e.g., store them in local storage
            oktaAuth.tokenManager.setTokens(tokens);

            // Verify the token with the API server
            const accessToken = oktaAuth.tokenManager.get('accessToken');
            console.log('accessToken:', accessToken);
            if (accessToken) {
              await this.verifyTokenWithApiServer(tokens.tokens.accessToken.value);
            } else {
              console.error('Access token is missing');
            }
            // Redirect to the desired route after successful authentication
            this.$router.replace('/dashboard');
          } catch (error) {
              this.errorDescription = 'There is no user associated with this email address';
              this.errorList = [];
              this.errorNotification = true;
              return;
          }
        },
      methods: {
        async verifyTokenWithApiServer(accessToken) {
              try {
                    const response = await this.$http.get(api.SIGNIN_URL, {
                      headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${accessToken}`
                      }
                    });
                    const { contact, token, cid } = response.data.data;
                    if (!contact.roles.length) {
                      console.log("Insufficient Roles: ");
                      this.stopLoading(this.$options.name);
                        this.stopLoading(this.$options.name);
                        this.errorDescription = 'There is no user associated with this email address';
                        this.errorList = [];
                        this.errorNotification = true;
                        return;
                    } else {
                        console.log("success authentication :::");
                        await this.authenticate(contact, token, cid);
                    }
                  } catch (err) {
                    console.error("Error in verifyTokenWithApiServer: ", err);
                    this.errorDescription = err;
                    this.errorList = [];
                    this.errorNotification = true;
                    return;
                  }
        },
        async authenticate(contact, token, cid){
                var redirect = this.$route.query.redirect || '';

                this.$store.dispatch('authenticationStore/setAuthenticated', {
                    is_authenticated: true,
                    auth_token: token,
                    cid
                });

                this.$store.dispatch('authenticationStore/setUserData', { contact });
                
                this.$store.dispatch('statusStore/setStatus', {
                    status: null,
                    message: ""
                });
                
                if(this.getCompanyStatus && this.getCompanyStatus != 'launched'){
                    redirect = '/onboarding'
                }
                else if(contact.roles?.includes('admin')){
                    // this.$store.dispatch('charmSubscriptionStore/login');
                    redirect = redirect ||  '/dashboard';
                }
                    
                this.errorNotification = false;
                // this.stopLoading(this.$options.name);
                this.$router.push(redirect);

            },
      }
    }

</script>
